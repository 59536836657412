<template>
  <figure
    class="image"
    :class="{
      'is-24x24': size === 'small',
      'is-32x32': size === 'medium'
    }"
  >
    <img
      v-if="imageExists"
      :src="svgFile"
    >
    <div
      v-else
      class="text"
    >
      {{ iconName }}
    </div>
  </figure>
</template>

<script setup>
import { ref, computed, watch } from 'vue';

const props = defineProps({
  iconName: {
    type: String,
    default: () => ''
  },
  size: {
    type: String,
    default: () => 'medium'
  }
});

const flagSVGs = import.meta.glob('../assets/images/flags/*.svg');
const svgFile = ref(null);
const imageExists = computed(() => svgFile.value !== null);
watch(
  () => props.iconName,
  async () => {
    if (flagSVGs[`../assets/images/flags/${props.iconName.toLowerCase()}.svg`]) {
      svgFile.value = null;
      svgFile.value = (await flagSVGs[`../assets/images/flags/${props.iconName.toLowerCase()}.svg`]()).default
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
img {
  border-radius: 4px;
}
.text {
  width: 32px;
  height: 32px;
  background-color: darkgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.5rem;
}
</style>