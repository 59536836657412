<template>
  <div
    class="currency-select"
    :class="{small: size === 'small', medium: size === 'medium', large: size === 'large'}"
  >
    <BaseCurrencyIcon
      :icon-name="iconName"
      :size="size"
    />
    <div
      class="select"
      :class="{'is-small': size === 'small'}"
    >
      <select
        :value="value"
        :disabled="disabled"
        @change="changed"
        @click.prevent
      >
        <option
          v-for="code in currencyCodes"
          :key="code"
        >
          {{ code }}
        </option>
      </select>
    </div>
  </div>
</template>

<script setup>
import BaseCurrencyIcon from './BaseCurrencyIcon.vue';
import { computed, inject } from 'vue';

const props = defineProps({
  value: {
    type: String,
    default: () => 'USD'
  },
  disabled: Boolean,
  size: {
    type: String,
    default: () => 'medium'
  }
});

const availableCurrencies = inject('availableCurrencies');
const currencyCodes = computed(() => availableCurrencies.map(c => c.code) );

const selectedCurrencyCode = computed(() => props.value);
const selectedCurrency = computed(() => availableCurrencies.find(c => c.code === selectedCurrencyCode.value));
const iconName = computed(() => {
  if (selectedCurrency.value)
    return selectedCurrency.value.icon;
  else
    return props.value;
});

const emit = defineEmits(['currency-changed']);
const changed = (event) => {
  emit('currency-changed', event.target.value);
};

</script>

<style lang="scss" scoped>
.currency-select {
  display: flex;
  align-items: center;
  gap: 5px;
}

select {
  border: none;
  border-radius: 0;
  background: transparent;
}

.currency-select.small {
  gap: 2px;
}
</style>